
export default {
  name: 'TopNav',
  props: {
    index: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      list: [
        {
          icon: 'icon-shouye',
          txt: '首页',
          url: '/'
        },
        // {
        //   icon: 'icon-xiangmu',
        //   txt: '项目',
        //   url: '/'
        // },
        {
          icon: 'icon-guanyu',
          txt: '关于我们',
          url: '/about'
        }
      ]
    }
  },
  methods: {
    jumpTo(url: string):void{
      (this as any).$router.push(url)
    },
  }
}
