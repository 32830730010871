
import { Options, Vue } from "vue-class-component";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

@Options({
  components: {
    Nav,
    Footer,
  },
})
export default class HomeView extends Vue {}
