
export default {
  name: "ButtomFooter",
  data() {
    return {};
  },
  methods: {
    jumpTo(url: string):void{
      (this as any).$router.push(url)
    },
  }
};
