
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: 'HomePage',
  data() {
    return {
      list: [{
        img: [require('../assets/project/shop.jpg'), require('../assets/project/shop1.jpg'), require('../assets/project/shop2.jpg')],
        title: '商城小程序',
        desc: ['功能齐全', '裂变推广']
      },{
        img: [require('../assets/project/app1.jpg'), require('../assets/project/app2.jpg')],
        title: 'OA系统',
        desc: ['定制功能', '长期稳定']
      },{
        img: [require('../assets/project/website1.jpg'), require('../assets/project/website2.jpg')],
        title: '商展活动',
        desc: ['快速交付', '质量上乘']
      }]
    }
  },
  components: {
    Nav,
    Footer,
  },
  created() {
    if (window.screen.width <= 500) {
      (this as any).$router.push('/m/home')
    }
  }
}
